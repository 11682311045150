import React, { useState } from "react";
import {
  EventCard,
  NewChampionsCard,
  NewContentV2,
  NewVideoPlayer,
} from "../../MainEvents/RecommendEventVideos/styles";
import { fetchApi, getPermissionStatus } from "../../../utils/methods";
import { ImageUrl } from "../../../utils/constants";
import { DeleteIcon, /*duplicateIcon,*/ editIcon } from "../../../utils/icons";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import HoverButton from "../../common/HoverButton";
import {
  DeleteSurveyLibraryV2,
} from "../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import DeleteSurveyPopUp from "./DeleteSurveyPopUp";
import Spinner from "../../common/Spinner";
import LazyImage from "../../common/LazyImage/LazyImage";
import parse from 'react-html-parser';

const SurveyCard = (props) => {
  const { history, userPermissions, survey, type, getSurveys, role } = props;
  const [onHover, setOnHover] = useState(null);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const launchSurveyLibrary = getPermissionStatus("Launch survey library", userPermissions);
  // const editSurveyLibrary = getPermissionStatus("Update survey library", userPermissions);

  const handleMouseEnter = (id) => setOnHover(id);
  const handleMouseLeave = () => setOnHover(null);

  const handleUseTemplate = async (surveyId) => {
    setIsLoading(true);
    history.push({ pathname: "/company/surveys/launch-custom-survey", state: { surveyId } });
  };

  const handleDeleteSurvey = async (surveyId) => {
    setIsLoading(true);
    try {
      const res = await fetchApi(`${DeleteSurveyLibraryV2}${surveyId}`, "DELETE");
      if (res.status === "failed") {
        toast.error(res?.data?.message);
      } else if (res.status === "success") {
        toast.success(res.data[0]);
        setIsDeletePopup(false);
        getSurveys();
      }
    } catch (error) {
      window.console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderActionButtons = (shouldRender) => (
    <div style={{ display: "flex" }}>
      {shouldRender && (<>
      
        <HoverButton
          title={"Edit Survey"}
          onClick={() =>
            history.push({
              pathname: `/company/surveys/edit-survey-library/${survey.id}`,state: { type, companyId: survey.company_id }
            })
          }
          svgPath={editIcon()}
        />
        
   
        {/* Uncomment to use the duplicate feature
        {editSurveyLibrary && (
          <HoverButton
            title={"Duplicate Survey"}
            onClick={() => DuplicateSurvey(survey.id)}
            svgPath={duplicateIcon()}
          />
        )} */}
   
        <HoverButton
          title={"Delete Survey"}
          onClick={() => setIsDeletePopup(true)}
          svgPath={DeleteIcon()}
        />
      </>)}
    </div>
  );

  const renderCategoryLabel = () => (
    <div
      style={{
        background: "rgba(0, 92, 135, 0.05)",
        borderRadius: "3px",
        padding: "0px 10px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          color: "rgba(0, 92, 135, 0.60)",
          fontFamily: "Rubik-Regular",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        {survey?.category_name}
      </span>
    </div>
  );

  return (
    <>
      <NewChampionsCard grid={0} margin={"25px"} style={{ marginBottom: "25px", height: "100%" }}>
        <EventCard
          videoCSS={0}
          onMouseEnter={() => handleMouseEnter(survey.id)}
          onMouseLeave={handleMouseLeave}
          style={{
            marginBottom: "0px",
            minHeight: type === "woliba" ? (role === "ADMIN" ? "415px" : "360px") : "415px",
          }}
        >
          <NewVideoPlayer>
            <LazyImage
              src={`${ImageUrl}/${survey.image}`}
              alt={survey?.name}
              fallbackImage={`${ImageUrl}/admin-survey/survey_backdrop.png`}
            />
            {/* <img src={`${ImageUrl}/${survey.image}`} alt={survey.survey_name} style={{width:'295px', height:'190px', borderRadius:'6px 6px 0px 0px'}}/> */}
          </NewVideoPlayer>

          {onHover === survey.id && (
            <div className="educateImageBackground" style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  fontSize: "18px",
                  background: "white",
                  padding: "9px",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  width: "200px",
                  marginBottom: "15px",
                  color: "#005C87",
                  lineHeight: "20px",
                  fontFamily: "Rubik-Medium",
                }}
                onClick={() =>
                  history.push({
                    pathname: `/company/survey-preview/${survey.id}`,
                    state: { type, companyId: survey.company_id },
                  })
                }
              >
                Preview
              </div>

              {launchSurveyLibrary && (
                <div
                  className="getStarted"
                  style={{ background: "#005C87", width: "200px", borderRadius: "3px" }}
                  onClick={() => !isLoading && handleUseTemplate(survey.id)}
                >
                  {isLoading ? <Spinner color={"white"} /> : "Use Template"}
                </div>
              )}
            </div>
          )}

          <NewContentV2 newPadding={"newPadding"} style={{ minHeight: "auto" }}>
            <div
              className="firstContainer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                className="name"
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  fontFamily: "Rubik-Medium",
                  width: "72%",
                  color: "#005C87",
                }}
                title={survey?.title}
              >
                {survey?.title}
              </div>
              <div
                className="quiz"
                style={{
                  color: "rgba(0, 92, 135, 0.6)",
                  lineHeight: "20px",
                  fontSize: "16px",
                }}
              >
                {survey?.library_questions_count} Questions
              </div>
            </div>
          </NewContentV2>

          <div
            style={{
              width: "100%",
              margin: "0px 15px 15px 15px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span
              style={{
                color: "#005C87",
                textAlign: "justify",
                fontFamily: "Rubik-Regular",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              {parse(survey.description)}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginRight: "15px",
              alignItems: "center",
              textTransform: "capitalize",
              marginBottom: "16px",
            }}
          >
            {   renderActionButtons((role === "ADMIN" && type === "woliba") || (role !== "ADMIN" && type === "organization")|| (role === "ADMIN" && type === "organization"))}
            {renderCategoryLabel()}
          </div>
        </EventCard>
      </NewChampionsCard>

      {isDeletePopup && (
        <DeleteSurveyPopUp
          showModal={isDeletePopup}
          onClose={() => setIsDeletePopup(false)}
          onYesClick={() => handleDeleteSurvey(survey.id)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

SurveyCard.propTypes = {
  history: PropTypes.object,
  survey: PropTypes.object,
  userPermissions: PropTypes.array,
  type: PropTypes.string,
  getSurveys: PropTypes.func.isRequired,
  role: PropTypes.string,
};

export default withRouter(SurveyCard);
